<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getWebStatus } from '@/api/common/website';
export default {
  name: 'App',
  components: {},
  methods: {
    handleResize() {
      if (window.innerWidth <= 768) {
        this.$globalState.isMobile = true;
        // console.log('是手机');
      } else {
        this.$globalState.isMobile = false;
        // console.log('是电脑');
      }
    },
  },
  created() {
    getWebStatus(1).then((res) => {
      this.isGrey = res.data.data.state;
      if (this.isGrey === 1) {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `body * {
          -webkit-filter: grayscale(100%); 
          -moz-filter: grayscale(100%); 
          -ms-filter: grayscale(100%); 
          -o-filter: grayscale(100%);
          filter: grayscale(100%);
          filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
          filter:gray;
          }`;
        document.getElementsByTagName('head')[0].appendChild(style);
      }
    });
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style></style>
