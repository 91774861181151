import defaultSetting from '@/setting';

const title = defaultSetting.title || '瑞京资产';

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return ` ${title} - ${pageTitle}`;
  }
  return `${title}`;
}
